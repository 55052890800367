@media (max-width: 1300px) {
  .stepCard {
    zoom: 0.85;
  }
}


@media (max-height: 770px) {
  .stepCard {
    zoom: 0.9;
  }
}

@media (max-height: 660px) {
  .stepCard {
    zoom: 0.75;
  }
}