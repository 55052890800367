
.mainwrapper-container {
  max-width: none !important;
  padding: 20px;
  height: 100vh;
  /* background-image: linear-gradient(to top, #0a1935 0%, #091d43 1%, #001f54 100%); */
  background: url(./saunabg2.avif);
  background-size: cover;

}

.mainwrapper-childcontainer {
  margin-top: 1vh !important;

}


.welcome-container {
  padding: 0px !important;
  max-width: none !important;

}


.sellwrapper-container {
  padding: 0px !important;
  max-width: none !important;

  
}

.yourinvtext-container{
  background-color: #f39c12;
  border: 1px solid #f39c12;
  height: 35px;
  text-align: center;
  border-top-left-radius: 15px;
  border-right: 1px solid black;
}

.tosell-container {
  background-color: #f39c12;
  border: 1px solid #f39c12;
  height: 35px;
  text-align: center;
  border-top-right-radius: 15px;
  
}

.yourinvtext {
  transform: translateY(-35%);
  font-size: 20px;
}

.toselltext {
  transform: translateY(-45%);
  font-size: 20px;
}


.inv-container {
  overflow-x: hidden;
  overflow-y: scroll;

  scrollbar-width: thin;
  scrollbar-color: rgb(68, 68, 68) #27273f00;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(68, 68, 68);
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.51);
  }

  /* Default height for screens 700px and above */
  height: 70vh;

  /* Media query for screens under 700px */
  @media (max-height: 900px) {
    height: 63vh !important;
  }
  
}


.sell-container {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 63vh;

  scrollbar-width: thin;
  scrollbar-color: rgb(68, 68, 68) #27273f00;
    /* You can adjust the colors */
  
  &::-webkit-scrollbar {
      width: 4px;
      /* Adjust the width of the scrollbar */
  }
  
  &::-webkit-scrollbar-thumb {
      background-color: rgb(68, 68, 68);
      /* Color of the thumb */
  }
  
  &::-webkit-scrollbar-track {
      background-color: #27273f00;
      /* Color of the track */
  }

    @media (max-height: 900px) {
      height: 55.3vh;
    }

    @media (min-height: 1025px) {
    height: 62.9 vh !important;
    } 

}


.cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /* Adjust as needed */
}

.card {
  flex: 1 1 calc(33.33% - 10px);
  /* Adjust the width and margin based on your requirements */
}

@media screen and (max-width: 600px) {
  .card {
    flex: 1 1 calc(50% - 10px);
  }
}

@media screen and (min-width: 601px) and (max-width: 1200px) {
  .card {
    flex: 1 1 calc(33.33% - 10px);
  }
}

@media screen and (min-width: 1201px) and (max-width: 1600px) {
  .card {
    flex: 1 1 calc(25% - 10px);
  }
}

@media screen and (min-width: 1601px) and (max-width: 1700px) {
  .card {
    flex: 1 1 calc(20% - 10px);
  }
}

@media screen and (min-width: 1701px) {
  .card {
    flex: 1 1 calc(16.66% - 10px);
  }
}



